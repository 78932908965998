import React from 'react'
import './homePageAnimation.scss'

const HomePageAnimation = () => {

  return (
    <>
      <img className='animated logo' src="https://roderick-cardenas-portfolio.s3.eu-west-2.amazonaws.com/logo-white.svg" alt=""/>
      <img className='animated spinner' src="https://roderick-cardenas-portfolio.s3.eu-west-2.amazonaws.com/spinner-shark-white.svg" alt=""/>
      <div className='slide left'></div>
      <div className='slide right'></div>
    </>
  )
}

export default HomePageAnimation